import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import horseIcon from '../imgs/HorsePath.png'
import horseIconWhite from '../imgs/HorsePathWhite.png'
import cowIcon from '../imgs/CowPath.png'
import cowIconWhite from '../imgs/CowPathWhite.png'
import goatIcon from '../imgs/GoatPath.png'
import goatIconWhite from '../imgs/GoatPathWhite.png'
import { motion } from 'framer-motion'
const NavGridItem = (props) => {
    const [isHovered, setIsHovered] = useState(false)
    const navigate = useNavigate();

    return (
        <motion.div className=" px-[2px] py-[2px] text-center hover:!text-white hover:border-[2px] hover:border-[#cc262c] bg-white cursor-pointer group"
            whileHover={{ scale: 1.02 }}
            onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={() => {
                if (props.link === 'Shop') {
                    navigate('/shop/clothing');
                }
                if (props.link === 'goatfarm') {
                    navigate('/goatfarm');
                }
                if (props.link === 'Contact') {
                    navigate('/Contact');
                }
            }}>
            <div className='flex flex-col items-center justify-center w-full h-full  hover:border-[1px] hover:border-[#cc262c] hover:bg-[#cc262c] nav-grid-item' >
                {props.link == 'Shop' &&
                    <>
                        <img
                            src={isHovered ? horseIconWhite : horseIcon} // Switch image source based on hover state
                            className='h-[70px] w-[70px] mb-1'
                            alt="Horse Icon"
                        />

                    </>
                }
                {(props.link == 'Contact') &&
                    <>
                        <img
                            src={isHovered ? cowIconWhite : cowIcon} // Switch image source based on hover state
                            className='h-[70px] w-[70px] mb-1'
                            alt="Horse Icon"
                        />

                    </>
                }
                {(props.link == 'goatfarm') &&
                    <>
                        <img
                            src={isHovered ? goatIconWhite : goatIcon} // Switch image source based on hover state
                            className='h-[70px] w-[70px] mb-1'
                            alt="Goat Icon"
                        />

                    </>
                }
                <span className='mb-2 oswald'>{props.link}</span>
                <span className='w-[40px] border-t-[1px] border-[#cc262c] group-hover:border-white' />
            </div>
        </motion.div>
    )
}

export default NavGridItem