import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import goatlifeLogo from '../imgs/GOATFARM_without_c_white_430x.avif';
import { RiArrowDropDownLine } from "react-icons/ri";
import { CiSearch } from "react-icons/ci";
import { RiShoppingCart2Fill } from "react-icons/ri";
import { IoSearchOutline } from "react-icons/io5";
import client from '../shopifyClient';
import Cart from './Cart';

const NavBar = ({ scrollY }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [cartPopped, setCartPopped] = useState(false);
    const [products, setProducts] = useState([]);
    const [searchDroppedDown, setSearchDroppedDown] = useState(false);
    const [altSearchDroppedDown, setAltSearchDroppedDown] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [shopifyCollections, setShopifyCollections] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    const fetchProductsFromCollection = async (collectionIdOrHandle) => {
        try {
            const collection = await client.collection.fetchWithProducts(collectionIdOrHandle, { productsFirst: 10 });
            setProducts(collection.products);
            return collection.products;
        } catch (error) {
            console.error("Error fetching collection and products:", error);
            return [];
        }
    };

    const fetchCollections = async() => {
        try {
            const allCollections = await client.collection.fetchAll();
            console.log(allCollections)
            setShopifyCollections(allCollections);
        } catch (error) {
            console.error("Error fetching collections:", error);
        }
    };

    const handleOverlayClick = () => {
        setCartPopped(false);
    };

    const handleCartModalClick = (e) => {
        e.stopPropagation();
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value === '') {
            setSearchDroppedDown(false);
            setAltSearchDroppedDown(false);
        } else {
            if (scrollY < 800 && location.pathname === '/') {
                setAltSearchDroppedDown(true);
            } else {
                setSearchDroppedDown(true);
            }
        }

        const filtered = products.filter((product) => product.title.toLowerCase().includes((e.target.value.toLowerCase())));
        setSearchResult(filtered);
    };

    useEffect(() => {
        if (scrollY > 800 && altSearchDroppedDown) {
            setAltSearchDroppedDown(false);
        }
    }, [scrollY]);

    useEffect(() => {
        fetchCollections();
    }, []);

    return (
        <>
            {location &&
                <div className={`h-[73px] bg-[#cc262c] w-full fixed z-[100] ${(scrollY < 800 && location.pathname === '/') ? 'hidden' : 'visible'}`}>
                    <div className='w-full h-full px-8 flex justify-between items-center nav-outer'>
                        <div className='flex h-full items-center nav-link'>
                            <Link to="/" className='h-full flex items-center'>
                                <img src={goatlifeLogo} className='h-[80%] nav-logo' alt="Ninja" />
                            </Link>
                            <div className='flex px-8 h-full nav-links'>
                                <div className='nav-link neue-600 text-white px-4 cursor-pointer h-full flex items-center' onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
                                    <span>Shop</span>
                                    {/* <>
                                    <RiArrowDropDownLine className='mx-1 text-white' />
                                    {showDropdown && (
                                        <div className='absolute bg-white text-black top-[73px] rounded shadow-lg '>
                                            {shopifyCollections.map((collection) => (
                                                <Link key={collection.id} to={`/shop/${collection.handle}`} className='block px-4 py-2 hover:bg-gray-200'>
                                                    {collection.title}
                                                </Link>
                                            ))}
                                        </div>
                                    )}
                                    </> */}
                                </div>
                                <div className='nav-link neue-600 text-white px-4 cursor-pointer h-full flex items-center'>
                                    <Link to="/contact">Contact</Link>
                                </div>
                                <div className='nav-link neue-600 text-white px-4 cursor-pointer h-full flex items-center'>
                                    <Link to="/goatfarm">Goatfarm</Link>
                                </div>
                            </div>
                        </div>
                        <div className='items-center flex h-full'>
                            <RiShoppingCart2Fill className='mr-4 text-[#ffffffa8] text-[1.5rem] cursor-pointer' onClick={() => setCartPopped(true)} />
                            <div className='border-l-[1px] border-[#ffffff8a] h-full w-2 mr-2 ml-2 search-nav'></div>
                            <div className='w-[200px] bg-[#ffffff2a] border-[1px] border-[#ffffff2a] py-2 rounded-[0.5rem] cursor-pointer flex items-center search-nav'>
                                <CiSearch className='ml-4 mr-2 text-white' />
                                <div className='flex w-full'>
                                    <input className='w-full bg-[#ffffff00] text-white oswald' style={{
                                        width: '100%',
                                        borderRadius: '0.375rem',
                                        outline: 'none',
                                        appearance: 'none',
                                        transition: 'border-color 0.2s ease-in-out',
                                    }}
                                    value={searchTerm}
                                    onChange={(e) => handleSearch(e)}
                                    ></input>
                                    {searchDroppedDown &&
                                        <div className='absolute py-2 border-[1px] !border-t-[0px] border-[#ffffff2a] mt-8 w-[200px] ml-[-2.35rem] rounded-b-[0.5rem] bg-[#cc262c]'>
                                            {searchResult.map((result) => (
                                                <div key={result.id} className='flex w-full h-[2.5rem] items-center border-b-[1px] border-[#ffffff2a] px-4 justify-start cursor-pointer hover:bg-[#ffffff2a]' onClick={() => { navigate(`/shop/clothing/${result.handle}`); setSearchDroppedDown(false); setSearchTerm('') }}>
                                                    <img src={result.images[0].src} className='h-4/5 mr-2' />
                                                    <div className='flex w-3/4 text-white oswald '>
                                                        {result.title}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {(scrollY < 800 && location.pathname === '/') &&
                        <div className='fixed px-4 py-2 bg-[#ffffff8a] backdrop-blur-lg border-white border-[1px] top-0 right-0 mx-8 my-8 rounded-[0.5rem] flex items-center z-[100] alt-search-container'>
                            <IoSearchOutline className='text-white text-[1rem] mr-2' />
                            <input className='outline-none bg-none rounded-[0.5rem] oswald px-2 alt-search-bar text-[#0000008a] text-[0.7rem]' onChange={(e) => handleSearch(e)} />
                            {altSearchDroppedDown &&
                                <div className='absolute py-2 border-[1px] !border-t-[0px] border-[#ffffff2a] top-[60px] w-full ml-[-0.99rem] rounded-[0.5rem] bg-[#cc262c] z-[1000]'>
                                    {searchResult.map((result) => (
                                        <div key={result.id} className='flex w-full h-[2.5rem] items-center border-b-[1px] border-[#ffffff2a] px-4 justify-start cursor-pointer hover:bg-[#ffffff2a]' onClick={() => { navigate(`/shop/clothing/${result.handle}`); setAltSearchDroppedDown(false); setSearchTerm('') }}>
                                            <img src={result.images[0].src} className='h-4/5 mr-2' />
                                            <div className='flex w-3/4 text-white oswald alt-search-result-title'>
                                                {result.title}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    }
                </div>
            }
            {cartPopped &&
                <div className='w-[100vw] h-[100vh] items-center justify-end fixed flex bg-[#0000002a] z-[100]' onClick={handleOverlayClick}>
                    <Cart handleCartModalClick={handleCartModalClick} setCartPopped={setCartPopped} />
                </div>
            }
        </>
    );
};

export default NavBar;
